import React from "react";
import {graphql, Link} from "gatsby";
import Layout from "../components/Layout";
import Marquee from "react-fast-marquee";
import styled from "styled-components";
import BlogTag from "../components/BlogTag";
import { useLocation } from '@reach/router'

import {GatsbyImage, getImage} from "gatsby-plugin-image";

import MyPortableText from "../components/MyPortableText";
import Head from "../components/Head";


export const query = graphql`
query($slug: String){
sanityBlog(slug: {current: {eq: $slug}}) {
    title
    datum(formatString: "DD.MM.YY", locale: "de-DE")
  introtext
  blogtag
  author
  _rawBody
  slug { current }
  image{
    asset{
    gatsbyImageData
    }
  }
} 
allSanitySettings {
    edges {
      node {
        instalink
        fblink
        linkedinlink
      }
    }
  }   
}

    `;

const BlogContent = styled.div`
    background-color: #${({color}) => (color ? color : "ffffff")}; 
`;



const Article = ({data}) => {

    const location = useLocation();

    console.log(location);

    return (
        <Layout settings={data.allSanitySettings.edges[0].node} subfolder={true}>
            <Head title={data.sanityBlog.title}/>
            <Marquee gradient={false} speed={40} className={'mobilemargin'}>
                <div className="marqueeText color-orange">
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                    <span className="marqueeText__inner">BLOG</span>
                </div>
            </Marquee>
            <BlogContent color={'c1c9c2'} className="blogcontent">
                {data.sanityBlog.image.asset ? <GatsbyImage image={data.sanityBlog.image.asset.gatsbyImageData} alt={data.sanityBlog.title} className="blogheaderimg"></GatsbyImage> : <></>}
                <div className="blogintro__content">
                    <div className="blogintro__left">
                        <div className="blogtag__container desktop">
                            {data.sanityBlog.blogtag.map(node => (
                                <BlogTag color={'000000'}>{node}</BlogTag>
                            ))
                            }
                        </div>
                        <div className="blogintro__left__bot">
                            <p className="large mobile-small">{data.sanityBlog.datum}</p>
                            <p className="large mobile-small">{data.sanityBlog.author}</p>
                        </div>
                    </div>
                    <div className="blogintro__right">
                        <h4 className="uppercase">{data.sanityBlog.title}</h4>
                    </div>
                    <div className="blogtag__container mobile">
                        {data.sanityBlog.blogtag.map(node => (
                            <BlogTag color={'000000'}>{node}</BlogTag>
                        ))
                        }
                    </div>
                </div>
                <div className="blog__content">
                    <MyPortableText value={data.sanityBlog._rawBody} />
                </div>
                <div className="blogintro__content">
                    <div className="blogintro__left"></div>
                    <div className="blogintro__right">
                        <div className="blog__share__container">
                            <p className="large">Share:</p>
                            <p className="large link__container">
                                <Link to={location.href ? "https://www.facebook.com/sharer/sharer.php?u=" + location.href : "https://www.facebook.com/sharer/sharer.php?u=https://eniosol.de/blog/" + data.sanityBlog.slug.current} className="link first hover-moveup">FB</Link>
                                <span className="share__bullet"></span>
                                <Link to={location.href ? "https://www.linkedin.com/sharing/share-offsite/?url=" + location.href : "https://www.linkedin.com/sharing/share-offsite/?url=https://eniosol.de/blog/" + data.sanityBlog.slug.current} className="link hover-moveup">LI</Link>
                                <span className="share__bullet"></span>
                                <Link to={location.href ? "https://www.xing.com/app/user?op=share;url=" + location.href : "https://www.xing.com/app/user?op=share;url=https://eniosol.de/blog/" + data.sanityBlog.slug.current} className="link hover-moveup">XI</Link>
                                <span className="share__bullet"></span>
                                <Link to={location.href ? "mailto:?to=&body=Schau%20dir%20diesen%20Beitrag%20an:%0D%0A" + location.href + "&subject=" + data.sanityBlog.title : "mailto:?to=&body=Schau%20dir%20diesen%20Beitrag%20an:%0D%0Ahttps://eniosol.de/blog/" + data.sanityBlog.slug.current + "&subject=" + data.sanityBlog.title} className="link hover-moveup">MAIL</Link>
                            </p>
                        </div>
                    </div>
                </div>


            </BlogContent>


        </Layout>
    )
}

export default Article;

/*
                {data.sanityBlog.image !== null ? <GatsbyImage image={data.sanityBlog.image.asset.gatsbyImageData} alt={data.sanityBlog.title} className="blogheaderimg"></GatsbyImage> : <></>}

 */
